import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

/*React-Bootstrap components */
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

/*React-icons*/
import { BiLayerPlus } from "react-icons/bi";

/*Import API */
import { fetchPOST, fetchGET } from "../apiHelper";
import { vnosNovegaPartnerja, drzave } from "../listOfApi";

const AddProjectPartner = ({ role }) => {
  const [vnosError, setVnosError] = useState(false);
  const [seznamDrzav, setSeznamDrzav] = useState([]);
  const [partnerData, setPartnerData] = useState({
    davcna: "",
    nazivSlo: "",
    nazivEng: "",
    naslov: "",
    postnaStevilka: "",
    posta: "",
    drzavaID: "",
    spletnaStran: "",
    opomba: "",
    parentID: 0,
    oid: "",
    pic: "",
    erasmusCode: "",
  });

  const history = useNavigate();

  /********** PRIDOBIVANJE ŠIFRANTOV *************/
  useEffect(() => {
    fetchGET(drzave).then((drzaveSeznam) => {
      if (drzaveSeznam !== 0) {
        let drzave = drzaveSeznam.map((drzave) => {
          return {
            label: drzave.naziv,
            value: drzave.id,
          };
        });
        setSeznamDrzav(drzave);
      }
    });
  }, []);

  /* Funkcija za shranjevanje podatkov na backend */
  const handleSubmitForm = (e) => {
    e.preventDefault();
    fetchPOST(vnosNovegaPartnerja, partnerData).then((partner) => {
      if (partner === 1) {
        history(-1);
      } else {
        setVnosError(true);
        setTimeout(() => {
          setVnosError(false);
        }, 5000);
      }
    });
  };

  const handleFormData = (e) => {
    setPartnerData({
      ...partnerData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Container fluid>
      {role.partnerji === 2 && (
        <>
          <Row>
            <Col className="main-header font-weight-bold">
              <BiLayerPlus className="me-2" />
              Dodaj: <span>{partnerData.nazivSlo}</span>
            </Col>
          </Row>
          <Row>
            <Col className="header-divider">
              <hr />
            </Col>
          </Row>
          <Row>
            {vnosError && (
              <Col>
                <h2 className="text-danger">
                  Vnos projektnega partnerja ni uspel
                </h2>
              </Col>
            )}
          </Row>
          <Row>
            <Col className="mt-3">
              <Form onSubmit={handleSubmitForm}>
                <Row>
                  <Col>
                    <Form.Label>Naziv partnerja - uradni </Form.Label>
                    <Form.Control
                      name="nazivSlo"
                      type="text"
                      required
                      minLength="3"
                      maxLength="100"
                      value={partnerData.nazivSlo}
                      onChange={handleFormData}
                      placeholder="Naziv partnerja - uradni"
                    />
                  </Col>
                  <Col>
                    <Form.Label>Naziv partnerja - angleški</Form.Label>
                    <Form.Control
                      name="nazivEng"
                      type="text"
                      maxLength="100"
                      value={partnerData.nazivEng}
                      onChange={handleFormData}
                      placeholder="Naziv partnerja - angleški"
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <Form.Label>Davčna številka</Form.Label>
                    <Form.Control
                      name="davcna"
                      type="text"
                      maxLength="100"
                      value={partnerData.davcna}
                      onChange={handleFormData}
                      placeholder="Davčna številka"
                    />
                  </Col>
                  <Col>
                    <Form.Label>Naslov </Form.Label>
                    <Form.Control
                      name="naslov"
                      type="text"
                      required
                      minLength="3"
                      maxLength="100"
                      value={partnerData.naslov}
                      onChange={handleFormData}
                      placeholder="Naslov"
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <Form.Label>Poštna številka </Form.Label>
                    <Form.Control
                      name="postnaStevilka"
                      type="text"
                      required
                      minLength="3"
                      maxLength="100"
                      value={partnerData.postnaStevilka}
                      onChange={handleFormData}
                      placeholder="Poštna številka"
                    />
                  </Col>
                  <Col>
                    <Form.Label>Kraj </Form.Label>
                    <Form.Control
                      name="posta"
                      type="text"
                      required
                      minLength="3"
                      maxLength="100"
                      value={partnerData.posta}
                      onChange={handleFormData}
                      placeholder="Kraj"
                    />
                  </Col>
                  <Col>
                    <Form.Label>Država </Form.Label>
                    <Select
                      onChange={(value) => {
                        setPartnerData({
                          ...partnerData,
                          drzavaID: value.value,
                        });
                      }}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                      }}
                      options={seznamDrzav}
                      placeholder="Države"
                      isSearchable
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <Form.Label>Erasmus koda </Form.Label>
                    <Form.Control
                      name="erasmusCode"
                      type="text"
                      placeholder="Erasmus koda"
                      value={partnerData.erasmusCode}
                      onChange={handleFormData}
                    />
                  </Col>
                  <Col>
                    <Form.Label>OID </Form.Label>
                    <Form.Control
                      name="oid"
                      type="text"
                      placeholder="OID"
                      value={partnerData.oid}
                      onChange={handleFormData}
                    />
                  </Col>
                  <Col>
                    <Form.Label>PIC </Form.Label>
                    <Form.Control
                      name="pic"
                      type="text"
                      placeholder="PIC"
                      value={partnerData.pic}
                      onChange={handleFormData}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <Form.Label>Spletna stran </Form.Label>
                    <Form.Control
                      name="spletnaStran"
                      type="text"
                      minLength="3"
                      maxLength="100"
                      value={partnerData.spletnaStran}
                      onChange={handleFormData}
                      placeholder="Spletna stran"
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <Form.Label>Opomba </Form.Label>
                    <Form.Control
                      name="opomba"
                      type="text"
                      as="textarea"
                      maxLength="500"
                      value={partnerData.opomba}
                      onChange={handleFormData}
                      placeholder="Opomba"
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <Button variant="primary" type="submit">
                      Dodaj
                    </Button>
                    <Button
                      variant="outline-primary ms-3"
                      onClick={() => {
                        history(-1);
                      }}
                    >
                      Prekliči
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default AddProjectPartner;
